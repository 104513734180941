import classNames from 'classnames';
import { useShowAnimation } from 'hook/useShowAnimation';
import { useRef } from 'react';

interface Props {
  duration?: number;
  text: string;
}

export default function FadeinText({ duration = 1500, text }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const showAnimation = useShowAnimation(ref);

  return (
    <div ref={ref}>
      <span
        className={classNames(
          `transition-opacity `,
          {
            'opacity-0': !showAnimation,
          },
          {
            'opacity-100': showAnimation,
          },
        )}
        style={{
          transitionDuration: `${duration}ms`,
        }}>
        {text}
      </span>
    </div>
  );
}
