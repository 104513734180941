import classNames from 'classnames';
import Image from '@components/image';
import { ImageSectionProps } from '@components/image';
import { useShowAnimation } from 'hook/useShowAnimation';
import { useRef } from 'react';

export default function ScrollingImage(props: ImageSectionProps) {
  const { className, ...rest } = props;

  const ref = useRef<HTMLImageElement | null>(null);
  const showAnimation = useShowAnimation(ref);

  return (
    <Image
      ref={ref}
      className={classNames(className, 'transition duration-[1500ms]', {
        'scale-105': !showAnimation,
        'scale-100': showAnimation,
      })}
      {...rest}
    />
  );
}
