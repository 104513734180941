import { ArrowIcon } from '@components/icons';
import classNames from 'classnames';
import Container from '@components/container';
import { ContainerSize, ImageText, VideoText } from '@lib/types';
import FadeinText from '@components/animation/fadein-text';
import Link from 'next/link';
import ScrollingImage from '@components/animation/scrolling-image';
import Vimeo from '@components/vimeo';

type TeaserTextSectionProps = {
  data: ImageText | VideoText;
  fixedHeight: boolean;
};

export default function TeaserTextSection({
  data: {
    image,
    vimeoUrl,
    videoPreset,
    videoStartAtSecond,
    title,
    subTitle,
    link,
    layout,
    paragraph,
  },
  fixedHeight = false,
}: TeaserTextSectionProps) {
  const landscape = !image || image.width > image.height;
  const teaserStyles = classNames(
    'rounded-xl md:rounded-3xl col-span-12 overflow-hidden',
    {
      'md:order-1': layout === 'ImageLeft',
      'md:order-2': layout === 'ImageRight',
      'md:col-start-1 lg:col-start-2': landscape && layout === 'ImageLeft',
      'md:col-start-2 lg:col-start-3': !landscape && layout === 'ImageLeft',
      'md:col-span-6 lg:col-span-5': landscape,
      'md:col-span-5 lg:col-span-4': !landscape,
      'h-96 object-cover': fixedHeight,
    },
  );
  return (
    <Container size={ContainerSize.wide}>
      <div className="grid grid-cols-12 gap-1.5 md:gap-6 items-center">
        {image && (
          <ScrollingImage
            className={teaserStyles}
            src={image}
            height={image.height}
            width={image.width}
            alt={image.alternativeText}
            sizes="(max-width: 768px) 100vw,50vw"
          />
        )}
        {vimeoUrl && (
          <div className={teaserStyles}>
            <Vimeo
              url={vimeoUrl}
              preset={videoPreset}
              start={videoStartAtSecond}
            />
          </div>
        )}
        <div
          className={classNames(
            'mt-4 md:mt-0 col-start-2 col-span-10 md:col-span-4',
            {
              'md:col-start-3 order-1': layout === 'ImageRight',
              'md:order-2': layout === 'ImageLeft',
            },
          )}>
          <div className="text-iceblue-500 font-bold mb-4 text-17 leading-140 md:text-20 md:leading-124">
            <FadeinText text={subTitle} />
          </div>
          <div className="text-blue-800 text-22 leading-130 md:text-35 md:leading-130 font-bold">
            <FadeinText text={title} />
          </div>
          {paragraph && (
            <div
              className="prose image-text mt-8"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          )}
          {link && (
            <Link
              className={classNames(
                'uppercase font-bold text-13 leading-140 tracking-5 text-blue-800 inline-flex items-center mt-8 group',
              )}
              href={link.url}>
              {link.text}{' '}
              <ArrowIcon className="w-12 ml-4 transition-transform duration-500 group-hover:translate-x-2" />
            </Link>
          )}
        </div>
      </div>
    </Container>
  );
}
